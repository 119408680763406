import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';
import ScrollspyNav from 'react-scrollspy-nav';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import '../../../dynamic-pages/hsr-character-dynamic.scss';
import './hsr-guides.scss';
import { Alert, Col, Row } from 'react-bootstrap';
import { HSRSkill } from '../../../modules/hsr/skills/hsr-skill';
import { HSRStat } from '../../../modules/hsr/common/components/hsr-stat';
import { HSRRelicSet } from '../../../modules/hsr/common/components/hsr-relic-set';
import { HSRLightCone } from '../../../modules/hsr/common/components/hsr-light-cone';
import { HSRCharacter } from '../../../modules/hsr/common/components/hsr-character';
import {
  faBriefcaseMedical,
  faFlask,
  faHandFist
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const SRGuidesRappa: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-guide'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Sunday - First Impressions</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_sunday.webp"
            alt="Sunday - First impressions"
          />
        </div>
        <div className="page-details">
          <h1>Sunday - First Impressions</h1>
          <h2>
            Check the early access first impressions of Sunday - a new character
            coming soon to Honkai: Star Rail!
          </h2>
          <p>
            Last updated: <strong>27/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content character-hsr-v2 special-guide">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Disclaimer" />
              <Alert variant="primary alert-red">
                <p>
                  The information you can find in this article{' '}
                  <strong>
                    come from the Creator Experience server and as such are
                    subject to change
                  </strong>{' '}
                  - so while you can check out Sunday's kit details a bit early,
                  please keep that in mind. A full guide for Sunday will be
                  released as usual once the patch introducing his goes live.
                </p>
              </Alert>
              {/* <p>
                Also, here's a <strong>special code</strong> to celebrate
                Sunday's reveal!
              </p>
              <div className="codes">
                <Box isCentered>
                  <p className="code">KFWJG6L3N8M1V</p>
                  <p className="date">Released on 16.10.2024</p>
                </Box>
              </div>
              <p>
                You can also use this{' '}
                <a
                  href="https://hsr.hoyoverse.com/gift?code=KFWJG6L3N8M1V"
                  target="_blank"
                  rel="noreferrer"
                >
                  link here
                </a>{' '}
                to redeem the code in your web browser!
              </p> */}
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Introduction" />
              <StaticImage
                src="../../../images/starrail/robin/intro.webp"
                alt="Sunday"
              />
              <h6>Sunday's Profile</h6>
              <p>
                <strong className={`Imaginary`}>Sunday</strong> is a{' '}
                <strong className={`rarity-5`}>5★</strong> character from the{' '}
                <strong className={`Imaginary`}>Imaginary</strong> element who
                follows the{' '}
                <strong className={`Harmony`}>Path of The Harmony</strong>. The
                dream of the Order has dissipated, yet there are still those who
                will not give up on their original intent. The traveler whose
                wings were clipped... whereto shall his footsteps lead?
              </p>
              <h6>Sunday's Intro</h6>
              <p>
                Back from the grave (allegedly),{' '}
                <strong>
                  Sunday brings with him the light of redemption and really damn
                  good teammate buffs
                </strong>
                . Another character to add to the growing pile of Action
                Advancers, Sunday apparently got the redemption arc powerup
                because <strong>his abilities are extremely good</strong>.
              </p>
              <p>
                Sunday is an excellent Harmony support{' '}
                <strong>
                  specialising in 100% Action Advance for both a character and
                  their respective summoned entity if they have one.
                </strong>{' '}
                He provides a lot of the same value as Bronya does while also
                granting greater benefits when buffing characters with summons
                and being more SP positive at the same time. Sunday also
                provides a percent Max Energy restore for a chosen ally and
                grants them further CRIT DMG buffs based on Sunday's CRIT DMG.{' '}
                <strong>
                  He is extraordinarily potent for summoners and traditional DPS
                  alike.
                </strong>
              </p>
              <p>
                <strong>
                  Everywhere you might want to use Bronya or Sparkle, Sunday can
                  effortlessly fit in, sometimes even overtaking them in
                  performance
                </strong>
                . His Skill always lasting 2 turns means follow-up characters
                and counter characters have a much better time since they often
                attack outside of their turn, and his unique bonus benefits for
                summon-based characters means he automatically shoots up to be
                their best choice.
              </p>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Kit" />
              <p>
                First, let's go over Sunday's kit - so stats, skills, major
                traces and minor traces - and explain how they work.
              </p>
              <h6>Sunday's Stats (level 80)</h6>
              <Row xs={1} xxl={4} className="va-section">
                <Col>
                  <div className="info-list-row">
                    <div className="category">HP</div>
                    <div className="details">1242</div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">DEF</div>
                    <div className="details">534</div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">ATK</div>
                    <div className="details">640</div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">Speed</div>
                    <div className="details">96</div>
                  </div>
                </Col>
              </Row>
              <h6>Sunday's Skills</h6>
              <div className={`skills Imaginary`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="131301"
                        maxLevel={7}
                        element="Imaginary"
                        gen="20"
                        break_primary="10"
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Sunday's Basic Attack is a simple one-target attack and you
                never want to use it. NEVER.
              </p>
              <div className={`skills Imaginary`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="131302"
                        maxLevel={12}
                        element="Imaginary"
                        gen="30"
                        break_primary=""
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Sunday's Skill is like Bronya's - he selects a single ally
                character to immediately take action and increases their DMG
                dealt by up to 30%. With just this part alone it's quite a bit
                weaker than Bronya's 66% DMG buff, but there's more. If the
                targeted ally has a summon (such a Lightning-Lord or Numby), the
                DMG% boost is additionally increased by up to 50%. In either
                case, the DMG boost lasts for 2 turns.
              </p>
              <p>
                This means summon characters that get Advanced by Sunday have a
                whopping 80% DMG boost for 2 turns, meaning you don't need to
                worry about the summon missing out on damage from an overly
                short duration buff. If Sunday uses this Skill on a Harmony
                character, they'll get the DMG bonuses but will not have their
                Action Advanced.
              </p>
              <div className={`skills Imaginary`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="131303"
                        maxLevel={12}
                        element="Imaginary"
                        gen="5"
                        break_primary=""
                        break_secondary=""
                        ult_cost={130}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                For the cost of 130 Energy, Sunday chooses a single ally to
                buff, regenerating 20% of their Max Energy and turning the
                targeted ally and their summon into 'The Beatified'. This will
                grant the ally CRIT DMG equal to up to 30% of Sunday's CRIT DMG
                stat plus up to an extra 12%. We've seen this CRIT DMG boosting
                effect more than once now, but his is especially generous when
                it comes to scaling off of his own stat.
              </p>
              <p>
                'The Beatified' lasts for 3 turns and ticks down at the start of
                each of Sunday's turns. There's actually yet more interaction
                with his Skill, for if he uses his Skill on an ally designated
                as 'The Beatified', Sunday recovers 1 Skill Point. Yes you did
                read that correctly, not only does he get to place all these
                crazy buffs on an ally, he also gets to do it for 'free'. While
                he doesn't directly restore any SP, he also doesn't incur an SP
                cost to use his Skill, making him completely SP neutral in ideal
                cases.
              </p>
              <div className={`skills Imaginary`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="131304"
                        maxLevel={12}
                        element="Imaginary"
                        gen=""
                        break_primary=""
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Sunday's Talent makes it so that whenever he uses his Skill on
                an ally, that ally will also gain up to 20% CRIT Rate for 3
                turns. Yes that's right, 3 whole turns. This means that even if
                he's using his Skill on a character without a summon, you still
                get 30% DMG and 20% CRIT Rate, which is absolutely fantastic. On
                a character with a summon, 80% DMG and 20% CRIT Rate is an
                almost unheard of single-target boost to be giving out in
                singular turns without the aid of an Ultimate.
              </p>
              <div className={`skills Imaginary`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="131307"
                        maxLevel={1}
                        element="Imaginary"
                        gen=""
                        break_primary=""
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                The Technique is a pretty simple as it grants 50% DMG to the
                first characters Sunday will use his Skill on.
              </p>
              <h6>Sunday's Major Traces</h6>
              <div className={`skills traces Imaginary`}>
                <Row xs={1} xl={2} xxl={3}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Imaginary`}>
                          A2
                          <span>Major trace</span>
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">Rest Day's Longing</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring Imaginary`}>
                        When using Ultimate, if the Energy regenerated for the
                        target is less than 40, increases the regenerated Energy
                        to 40.
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Imaginary`}>
                          A4
                          <span>Major trace</span>
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">Exalted Sweep</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring Imaginary`}>
                        When battle starts, Sunday regenerates 25 Energy.
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Imaginary`}>
                          A6
                          <span>Major trace</span>
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">Haven in Palm</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring Imaginary`}>
                        When using Skill, dispels 1 debuff from the target.
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                His Bonus Abilities are extremely useful and here's what they
                do:
              </p>
              <ul>
                <li>
                  <strong>A2</strong> makes it so that his Ultimate's Energy
                  restore cannot restore less than 40 Energy in any
                  circumstance. For characters with very high Max Energy this
                  won't matter, but if someone has a very cheap Ultimate, Sunday
                  will still be able to grant them a massive influx of Energy.
                  40, to be precise.
                </li>
                <li>
                  <strong>A4</strong> simply grants Sunday an extra 25 Energy
                  upon combat start, speeding up the process of acquiring his
                  Ultimate and granting an ally 'The Beatified'.
                </li>
                <li>
                  <strong>A6</strong> will also allow his Skill to cleanse 1
                  debuff from an ally when he uses it.
                </li>
              </ul>
              <h6>Sunday's Minor Traces</h6>
              <div className="smaller-traces">
                <Row xs={1} xxl={3}>
                  <Col>
                    <div className="box trace">
                      <HSRStat stat="CRIT DMG" />{' '}
                      <span className="value">+37.3%</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="box trace">
                      <HSRStat stat="Effect RES" />{' '}
                      <span className="value">+18%</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="box trace">
                      <HSRStat stat="DEF%" />{' '}
                      <span className="value">+12.5%</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Eidolons" />
              <p>
                Now let's check Sunday's Eidolons and how they improve his
                performance.
              </p>
              <h6>Sunday's Eidolons</h6>
              <div className="skills eidolons">
                <Row xs={1} xxl={2}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Imaginary`}>E1</div>
                        <div className="skill-info">
                          <p className="skill-name">Millennium's Quietus</p>
                          <p className="skill-type">Eidolon 1</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Imaginary`}>
                        <p>
                          When Sunday uses Skill, the target character can
                          ignore <b>16%</b> of enemy target's DEF to deal DMG
                          and their summon can ignore <b>40%</b> of enemy
                          target's DEF to deal DMG, lasting for <b>2</b>{' '}
                          turn(s).
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Imaginary`}>E2</div>
                        <div className="skill-info">
                          <p className="skill-name">Faith Outstrips Frailty</p>
                          <p className="skill-type">Eidolon 2</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Imaginary`}>
                        <p>
                          After the first use of Ultimate, recovers <b>2</b>{' '}
                          Skill Point(s). The DMG dealt by "The Beatified"
                          increases by <b>30%</b>.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Imaginary`}>E4</div>
                        <div className="skill-info">
                          <p className="skill-name">Sculpture's Preamble</p>
                          <p className="skill-type">Eidolon 4</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Imaginary`}>
                        <p>
                          When the turn starts, regenerates <b>8</b> Energy.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Imaginary`}>E6</div>
                        <div className="skill-info">
                          <p className="skill-name">
                            Dawn of Sidereal Cacophony
                          </p>
                          <p className="skill-type">Eidolon 6</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Imaginary`}>
                        <p>
                          The Talent's CRIT Rate boost effect becomes stackable
                          up to <b>3</b> time(s), and the Talent's duration
                          increases by 1 turn(s). When Sunday uses Ultimate, can
                          also apply the Talent's CRIT Rate boost effect to the
                          target. When the Talent's CRIT Rate boost takes effect
                          and the target's CRIT Rate exceeds 100%, every{' '}
                          <b>1%</b> of excess CRIT Rate increases CRIT DMG by{' '}
                          <b>2%</b>.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <h6>Eidolons reviewed:</h6>
              <ul>
                <li>
                  <strong>E1</strong> allows the ally buffed by his Skill to
                  ignore 16% of enemies' DEF when dealing damage, and allows
                  their summons to ignore 40% instead, with this effect lasting
                  2 turns. This will translate to a lot additional damage,
                  especially for Summoners as DEF Shred scales the damage really
                  well.
                </li>
                <li>
                  <strong>E2</strong> Restores 2 SP when using the Ultimate for
                  the first time in a fight and additionally increases the DMG%
                  of 'The Beatified' by 30%. This Eidolon helps with the SP
                  economy at the start of the battle and gives another DMG%
                  boost to the carry.
                </li>
                <li>
                  <strong>E4</strong> lets Sunday regen 8 Energy at the start of
                  every turn, making it way easier to achieve a 3-turn rotation,
                  but it's actually easier to get the Signature rather than
                  invest into E4 to fix his energy issues.
                </li>
                <li>
                  <strong>E6</strong> allows the Talent's CRIT Rate to become
                  stackable up to 3 times, the duration goes to 4 turns, and
                  excess CRIT Rate becomes CRIT DMG. This is honestly an insane
                  Eidolon that will drastically increase the damage output of
                  the carry and makes building them easier.
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Relics and Light Cones" />
              <div className={`section-build Imaginary `}>
                <div className="build-stats">
                  <h6 className="with-margin">Recommended Main and Substats</h6>
                  <Row xs={1} xl={2} xxl={4} className="main-stats">
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Body</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="CRIT DMG" />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Feet</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="Speed" />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Planar Sphere</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="HP%" />{' '}
                          <span className="order">=</span>{' '}
                          <HSRStat stat="DEF%" />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Link Rope</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="Energy Regen Rate" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="box sub-stats">
                        <span>Substats:</span>
                        <p>
                          SPD (Until desired speed build) &gt; CRIT DMG &gt; EFF
                          RES% &gt; DEF% = HP%
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <br />
                <h6>Recommended Endgame Stats (while buffed in combat)</h6>
                <ul>
                  <li>
                    HP: <strong>4000 - 4500+</strong>
                  </li>
                  <li>
                    DEF: <strong>1000 - 1200+</strong>
                  </li>
                  <li>
                    CRIT DMG: <strong>180 - 220%+</strong>
                  </li>
                  <li>
                    Energy Regen: <strong>119.44 - 124.44%+</strong>
                  </li>
                  <li>
                    SPD (-1 SPD Build):{' '}
                    <strong>134 or 1 SPD slower than the carry</strong>
                  </li>
                  <li>
                    SPD (Hyperspeed):{' '}
                    <strong>160+ or as fast as possible</strong>
                  </li>
                </ul>
                <hr />
                <p>More info about the stats:</p>
                <ul>
                  <li>
                    <strong>SPEED</strong>:
                  </li>
                  <ul>
                    <li>
                      Sunday has two different Speed builds to choose from:{' '}
                      <strong>-1 Speed and Hyper Speed.</strong>
                    </li>
                    <li>
                      <strong>-1 Speed</strong>: This Speed build is Sunday's
                      most common and usually most powerful. Configure Sunday's
                      Speed to be at least 1 Speed slower than his desired
                      target (your carry), but ensure you account for in combat
                      Speed buffs. With this setup, Sunday's target will move
                      right before him, immediately after this Sunday can use
                      his skill on them advancing them 100%, effectively
                      granting them an additional turn. Sunday and his carry can
                      repeat this continually throughout the entire fight,
                      resulting in him granting his target double actions on top
                      of advancing their companion/minion and boosting their
                      damage significantly. This combo will work with any Speed
                      values as long as Sunday is consistently any amount slower
                      than his target - but the closer you can get Sunday's
                      speed to his carry the more efficient the combo will
                      become. The most common speed setup for this combo is:
                    </li>
                    <ul>
                      <li>Carry: 135+ Speed</li>
                      <li>Sunday: 134 Speed</li>
                    </ul>
                    <li>
                      <strong>Hyperspeed</strong>: As an alternative you can
                      completely forego building Speed on Sunday's carry/target
                      and instead build Sunday as fast as possible. With this
                      build, Sunday will always act before his target and can
                      effectively grant his Speed value to his ally and their
                      companion via his 100% action advancement. This build
                      offers significantly less actions in a fight than the -1
                      Speed build but will allow you to completely ignore the
                      Speed stat on your carry - opting for choices such as ATK%
                      Boots instead.
                    </li>
                    <li>
                      Both Speed builds are viable, but Hyperspeed requires more
                      investment and in many cases still comes out behind -1
                      Speed in a lot of teams, making it more of a niche build.
                    </li>
                  </ul>
                  <li>
                    <strong>CRIT DMG</strong>:
                  </li>
                  <ul>
                    <li>
                      Sunday's Ultimate scales with CRIT DMG and (at max rank)
                      it grants his target 30% of his CRIT DMG + 12%, meaning
                      stacking as much CRIT DMG on him as possible is important.
                      CRIT DMG is not more important than nailing your choice of
                      Speed build, but for every 10% CRIT DMG you gain on Sunday
                      his target will gain 3%.
                    </li>
                  </ul>
                  <li>
                    <strong>Energy Regen</strong>:
                  </li>
                  <ul>
                    <li>
                      Sunday is heavily incentivized to achieve a 3-action
                      rotation, in order to maintain full uptime on "The
                      Beatified". Because of this Energy Regen is a very
                      important stat. With no ER or assistance Sunday generates
                      the following energy:
                    </li>
                    <ul>
                      <li>
                        Skill: 30 x 3 = <strong>90 Energy</strong>
                      </li>
                      <li>
                        Ultimate Refund: <strong>5 Energy</strong>
                      </li>
                      <li>
                        Total: <strong>95 Energy</strong>
                      </li>
                      <li>
                        Total with Energy Rope: <strong>113.5 Energy</strong>
                      </li>
                      <li>
                        Total with Sunday Signature (S1) & Energy Rope:{' '}
                        <strong>142.1 Energy</strong>
                      </li>
                      <li>
                        Total with Bronya Signature (S1) & Energy Rope:{' '}
                        <strong>123 Energy</strong>
                      </li>
                      <li>
                        Total with Bronya Signature (S1), Energy Rope & Energy
                        Planar: <strong>127.7 Energy</strong>
                      </li>
                    </ul>
                    <li>
                      Based on this Sunday can effortless achieve his 3 action
                      rotation with his Signature and an Energy Rope equipped,
                      but without it he must prioritize alternative energy
                      Cones, pursue additional energy from Planars and rely on
                      being hit by an enemy to make up the last bit.{' '}
                      <strong>
                        Without an energy Cone Sunday will struggle to achieve a
                        3 action rotation.
                      </strong>
                    </li>
                  </ul>
                </ul>
                <div className="build-relics">
                  <h6>Recommended Relic Sets</h6>
                  <div className="detailed-cones moc extra planar">
                    <div className={`single-cone Imaginary`}>
                      <div className={`percentage default`}>
                        <p>1</p>
                      </div>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        Best set for Sunday's most common build (-1 Speed) and a
                        huge asset to your carry. Aim to get this set if
                        possible unless playing Hyperspeed.
                      </p>
                    </div>
                    <div className={`single-cone with-notes Imaginary`}>
                      <div className={`percentage`}>
                        <p>2</p>
                      </div>
                      <div className="double-set">
                        <HSRRelicSet
                          name="Messenger Traversing Hackerspace"
                          compactMode
                          comment="2PC"
                        />
                        <>
                          <div className="flex-placeholder">Flex</div>
                        </>
                      </div>
                    </div>
                    <div className={`information Imaginary`}>
                      <p>
                        If you can't put together a top recommended 4P set with
                        satisfactory sub/main stats, mix and match the 2P
                        Bonuses from the following instead:
                      </p>
                      <ul className="with-sets">
                        <li>
                          <HSRRelicSet
                            name="Sacerdos' Relived Ordeal"
                            compactMode
                            smol
                          />{' '}
                          <span className="value">[Speed +6%]</span>
                        </li>
                        <li>
                          <strong>Rainbow set</strong> - if you have great
                          pieces with Speed & CRIT DMG from other sets, you can
                          use them here.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h6>Special Relic Sets</h6>
                  <div className="detailed-cones moc extra planar">
                    <div className={`single-cone Imaginary`}>
                      <HSRRelicSet
                        name="Eagle of Twilight Line"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        Allows characters with accessible ultimates to reach
                        higher Speed (and cycle) breakpoints compared to just
                        stacking Speed. Strongest on characters with powerful
                        Ultimate related effects or who can access their
                        ultimate at least once every 3 (but ideally 2) actions.
                        Particularly strong when combined with other action
                        advancement effects. If playing Sunday according to the
                        -1 Speed build, exercise caution when using this set as
                        it can ruin your Speed tuning unless carefully managed
                        (challenging).{' '}
                        <strong>
                          Primarily used with Hyperspeed Sunday to get more
                          actions.
                        </strong>
                      </p>
                    </div>
                    <div className={`single-cone Imaginary`}>
                      <HSRRelicSet
                        name="Messenger Traversing Hackerspace"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        Good team-wide supportive option for characters with
                        Ultimates which target allies. With this set equipped
                        ultimates are best timed carefully to ensure the Speed
                        buff is active on key members for as long as possible.{' '}
                        <strong>
                          If playing Sunday according to the -1 Speed build,
                          exercise caution when using this set as it can ruin
                          your Speed tuning unless carefully managed
                          (challenging).
                        </strong>
                      </p>
                    </div>
                  </div>
                  <h6 className="with-margin">Recommended Planetary Sets</h6>
                  <div className="detailed-cones moc extra planar">
                    <div className={`single-cone with-notes Imaginary`}>
                      <div className={`percentage`}>
                        <p>1</p>
                      </div>
                      <HSRRelicSet
                        name="Lushaka, the Sunken Seas"
                        compactMode
                      />
                    </div>
                    <div className={`information Imaginary`}>
                      <p>
                        Top option for Supporting a single ATK-scaling DPS, and
                        for its Energy Regen.
                      </p>
                    </div>
                    <p className="with-margin-top">
                      The following Planars are all usable and have their own
                      strength and weaknesses when looking for team-based
                      supportive effects.{' '}
                      <strong>
                        Energy Regen Planars increase the consistency of
                        achieving a 3-action Ultimate with Sunday when playing
                        without his Signature - something he very much wants to
                        do giving them an edge over non-ER options.
                      </strong>
                    </p>
                    <ul className="with-sets">
                      <li>
                        <HSRRelicSet name="Broken Keel" compactMode smol /> - An
                        option for supports stacking Effect RES, while also
                        increasing the entire team's CRIT DMG. Requires at least
                        30% Effect RES to activate.
                      </li>
                      <li>
                        <HSRRelicSet
                          name="Fleet of the Ageless"
                          compactMode
                          smol
                        />{' '}
                        - Ideal for supporting teams that scale on ATK.
                      </li>
                      <li>
                        <HSRRelicSet
                          name="Penacony, Land of the Dreams"
                          compactMode
                          smol
                        />{' '}
                        - Top option for supporting teams with the same Element
                        as the wearer, and for its Energy Regen.
                      </li>
                    </ul>
                  </div>
                  <h6 className="with-margin">Special Planetary Sets</h6>
                  <div className="detailed-cones moc extra planar">
                    <div className={`single-cone with-notes Imaginary`}>
                      <HSRRelicSet name="Sprightly Vonwacq" compactMode />
                    </div>
                    <div className={`information Imaginary`}>
                      <p>
                        Only useful when playing Hyperspeed Sunday to achieve a
                        faster start and/or more actions in the first
                        cycle/portion of a fight. When playing -1 Sunday using
                        this will only ruin your initial Speed tuning.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="build-cones">
                  <h6>Recommended Light Cones</h6>
                  <>
                    <div className="detailed-cones moc">
                      <div className={`single-cone Imaginary`}>
                        <div className="percentage">
                          <p>1</p>
                        </div>
                        <HSRLightCone
                          slug="a-grounded-ascent"
                          mode="profile"
                          superImp="1"
                        />
                      </div>
                      <div className="information">
                        <p>
                          Best Light Cone option for Sunday by far, allowing him
                          to realistically hit a 3-action rotation and granting
                          permanent uptime on his Ultimate buff. Also allows him
                          to be played Skill Point positive.
                        </p>
                      </div>
                      <div className={`single-cone Imaginary`}>
                        <div className="percentage">
                          <p>2</p>
                        </div>
                        <HSRLightCone
                          slug="but-the-battle-isnt-over"
                          mode="profile"
                          superImp="1"
                        />
                      </div>
                      <div className="information">
                        <p>
                          Decent alternative to Sunday's signature albeit a
                          significantly downgrade. Still grants him additional
                          buffing capabilities, Skill Points and Energy Regen,
                          but not as much. Which leads to a higher reliance on
                          enemy attacks to hit a 3-action rotation. Note
                          compared to his signature this Cone's buff lasts 1
                          turn making it significantly worse for the summon
                          based characters Sunday specializes in supporting.
                        </p>
                      </div>
                      <div className={`single-cone Imaginary`}>
                        <div className="percentage">
                          <p>3</p>
                        </div>
                        <HSRLightCone
                          slug="past-and-future"
                          mode="profile"
                          superImp="5"
                        />
                      </div>
                      <div className="information">
                        <p>
                          A much worse version of But the Battle Isn't Over,
                          having the same 1 turn duration issue. And on top,
                          it's missing Energy Regen or Skill Point economy.
                          Sunday may struggle to achieve a 3-action ultimate
                          when using this Cone.
                        </p>
                      </div>
                    </div>
                  </>
                  <h6>Special Light Cones</h6>
                  <>
                    <div className="detailed-cones moc">
                      <div className={`single-cone Imaginary`}>
                        <HSRLightCone
                          slug="dance-dance-dance"
                          mode="profile"
                          superImp="5"
                        />
                      </div>
                      <div className="information">
                        <p>
                          If playing Sunday according to the -1 Speed build
                          exercise caution when using this Cone as it can ruin
                          your Speed tuning unless carefully managed.
                          Alternatively is a solid option for Hyperspeed Sunday,
                          if you're lacking his signature. Sunday may struggle
                          to achieve a 3-action ultimate when using this Cone.
                        </p>
                      </div>
                    </div>
                  </>
                </div>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Sunday vs Bronya vs Sparkle" />
              <div className="employee-container for-nikke">
                <HSRCharacter mode="icon" slug="sunday" enablePopover />
                <HSRCharacter mode="icon" slug="bronya" enablePopover />
                <HSRCharacter mode="icon" slug="sparkle" enablePopover />
              </div>
              <p>
                We have created a separate guide that compares{' '}
                <strong className="Imaginary">Sunday</strong> with{' '}
                <strong className="Quantum">Sparkle</strong> and{' '}
                <strong className="Wind">Bronya</strong> who are his direct
                competitors in the Hypercarry Amplifier role. Check this article
                here:
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Sparkle vs Bronya vs Sunday"
                  link="/star-rail/guides/sparkle-vs-bronya-vs-sunday"
                  image={
                    <StaticImage
                      src="../../../images/starrail/categories/category_spabro.webp"
                      alt="Sparkle vs Bronya vs Sunday"
                    />
                  }
                />
              </Row>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Synergy" />
              <p>
                <strong>
                  Sunday is one of the most flexible and synergistic character's
                  in the game
                </strong>{' '}
                - so much so there are far too many synergies to list. Instead
                below is a list of synergistic features Sunday offers and how
                they impact each archetype he can be played within.
              </p>
              <p>
                Sunday's buffs last multiple turns and can be maintained
                indefinitely,{' '}
                <strong>
                  making him a suitable choice for characters with Summons but
                  also Follow-Ups
                </strong>{' '}
                - something he holds as an advantage over his most comparable
                peer Bronya. This is relevant for characters such as:
              </p>
              <ul className="bigger-margin">
                <li>
                  <HSRCharacter mode="inline" slug="feixiao" enablePopover />{' '}
                  (FUA)
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="dr-ratio" enablePopover />{' '}
                  (FUA)
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="yunli" enablePopover />{' '}
                  (FUA)
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="clara" enablePopover />{' '}
                  (FUA)
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="blade" enablePopover />{' '}
                  (FUA)
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="jing-yuan" enablePopover />{' '}
                  (Lightning Lord)
                </li>
              </ul>
              <p>
                Sunday possesses no ATK buffs and instead is{' '}
                <strong>
                  entirely focused on Action Advance, CRIT and DMG% buffs,
                  making him a reasonable choice for any character no matter if
                  they scale off HP, ATK or DEF.
                </strong>{' '}
                This type of scaling allows him to be played universally, but
                also make him strong with either characters that don't care much
                for additional main stat scaling as they have it covered with
                their own kit or other supporting characters that can offer a
                tremendous amount of main stat scaling themselves to complement
                Sunday.
              </p>
              <p>
                <strong>Sunday's Energy Generation is incredibly potent</strong>{' '}
                for most damaging characters but is very appealing to a few
                whose kits focus very heavily around Ultimates such as:
              </p>
              <ul className="bigger-margin">
                <li>
                  <HSRCharacter mode="inline" slug="argenti" enablePopover />{' '}
                  (Ultimate Hungry)
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="yunli" enablePopover />{' '}
                  (Ultimate Hungry)
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="boothill" enablePopover />{' '}
                  (Ultimate Hungry)
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="jingliu" enablePopover />{' '}
                  (Ultimate Hungry)
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="jing-yuan" enablePopover />{' '}
                  (Ultimate Hungry)
                </li>
                <li>
                  <HSRCharacter
                    mode="inline"
                    slug="imbibitor-lunae"
                    enablePopover
                  />{' '}
                  (Ultimate offers nice SP sustain)
                </li>
              </ul>
              <p>
                <strong>
                  Unlike Bronya, Sunday is Skill Point neutral and with certain
                  Cone even slightly Skill Point positive.
                </strong>{' '}
                This opens up the -1 Speed playstyle to far more characters,
                while also making Sunday far more playable in teams that are
                tight on Skill Points than Bronya ever was. Here are just a few
                characters who can benefit from the less SP heavy -1 Speed
                playstyle (there are many more than this):
              </p>
              <ul className="bigger-margin">
                <li>
                  <HSRCharacter mode="inline" slug="acheron" enablePopover />{' '}
                  (Double Actions results in more ultimates)
                </li>
                <li>
                  <HSRCharacter
                    mode="inline"
                    slug="imbibitor-lunae"
                    enablePopover
                  />{' '}
                  (More Ults + SP Neutral allows DHIL to weave Double Action 3SP
                  Basics into his rotation)
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="jingliu" enablePopover />{' '}
                  (Can much more comfortably play the Double Action playstyle
                  and with greater team flexibility)
                </li>
              </ul>
              <p>
                <strong>
                  Sunday is a Summon specialist due to his additional bonuses to
                  summoners and his ability to advance their summons
                </strong>{' '}
                - for this reason he is uniquely synergistic with:
              </p>
              <ul className="bigger-margin">
                <li>
                  <HSRCharacter mode="inline" slug="jing-yuan" enablePopover />{' '}
                  (Can advance Lightning Lord... Finally)
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="topaz" enablePopover /> (For
                  the very niche Main DPS Topaz teams)
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="lingsha" enablePopover />{' '}
                  (For those playing main DPS Lingsha)
                </li>
              </ul>
              <p>
                Due to being Skill Point Neutral, Sunday can actually be played
                alongside his fellow action advancers - Bronya and Sparkle - to
                create some very interesting combinations. Here are some of the
                more potent ways to combine the characters:
              </p>
              <ul className="bigger-margin">
                <li>
                  <HSRCharacter mode="inline" slug="sunday" enablePopover /> &{' '}
                  <HSRCharacter mode="inline" slug="sparkle" enablePopover /> -
                  Do not build Speed on your carry and instead set Sparkle's
                  Speed to 161 (or higher) and Sunday's to 1 less than that.
                  Using this combo, Sparkle will move first, advancing you carry
                  to her position in the Action Odrer for them to move,
                  following that Sunday will move right after, dragging the
                  carry back again for another action. This combo allows a carry
                  with 0 Speed to perform an action twice at an effective 160
                  Speed, combining both the -1 playstyle and Hyperspeed
                  playstyle. The downside of this combo is that Sparkle's skill
                  buff only lasts for 1 turn and will not be active for the turn
                  you advance your carry with Sunday.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="sunday" enablePopover /> &{' '}
                  <HSRCharacter mode="inline" slug="bronya" enablePopover /> -{' '}
                  Build your teams Speed as follows - Carry at 136 or higher,
                  Sunday at least 1 Speed slower than that and Bronya at least 1
                  Speed slower than Sunday (for example, Carry: 136, Sunday:
                  135, Bronya: 134). With this setup, the carry will move first
                  using their action, Sunday will move next buffing them and
                  granting them another action, then right after that Bronya
                  will move granting them yet another action and stacking her
                  buffs on top of Sunday's. Unlike the Sparkle combo, Sunday and
                  Bronya's buffs do overlap for a massive 3rd strike and you'll
                  also be consistently outputting 3 actions consecutively every
                  time you carry move so long as you can keep up with Skill
                  Point demands.
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-7">
              <SectionHeader title="Teams" />
              <h6>Notes about Sunday Teams</h6>
              <ul>
                <li>
                  Sunday excels in a wide variety of teams and can act as a
                  substitution or sometimes even upgrade in any team that uses
                  Sparkle or Bronya.
                </li>
                <li>
                  Sunday also works in teams that units like Bronya otherwise
                  wouldn't due to his Skill point Neutrality and Energy
                  Generation.
                </li>
                <li>
                  Sunday also has a substantial edge over his aforementioned
                  peers and a lot of other supports in teams featuring Summoners
                  and Companions due to his unique synergy with that archetype.
                </li>
                <li>
                  Finally Sunday is also Follow-Up Friendly thanks to his buffs
                  lasting multiple turns (compared to a character like Bronya
                  whose only last one), further increasing his flexibility.
                </li>
              </ul>
              <p>Here are just a few of his many teams:</p>
              <div className={`character-teams box Imaginary`}>
                <div className="team-header">
                  <p>King Yuan Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="jing-yuan"
                          enablePopover
                        />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="sunday" enablePopover />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character multi-equal small">
                        <HSRCharacter mode="icon" slug="robin" enablePopover />
                        <HSRCharacter
                          mode="icon"
                          slug="tingyun"
                          enablePopover
                        />
                        <HSRCharacter
                          mode="icon"
                          slug="ruan-mei"
                          enablePopover
                        />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main nothing">
                        <p>Any sustain</p>
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Cooler Daniel Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="imbibitor-lunae"
                          enablePopover
                        />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="sunday" enablePopover />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character multi-equal small">
                        <HSRCharacter mode="icon" slug="robin" enablePopover />
                        <HSRCharacter
                          mode="icon"
                          slug="tingyun"
                          enablePopover
                        />
                        <HSRCharacter
                          mode="icon"
                          slug="ruan-mei"
                          enablePopover
                        />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main nothing">
                        <p>Any sustain</p>
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Triple Action Blade Team (May run into SP issues)</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="blade" enablePopover />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="sunday" enablePopover />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="bronya" enablePopover />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="luocha" enablePopover />
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Boothill Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="boothill"
                          enablePopover
                        />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="sunday" enablePopover />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="ruan-mei"
                          enablePopover
                        />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character multi-equal small">
                        <HSRCharacter
                          mode="icon"
                          slug="lingsha"
                          enablePopover
                        />
                        <HSRCharacter
                          mode="icon"
                          slug="gallagher"
                          enablePopover
                        />
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Argenti Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="argenti"
                          enablePopover
                        />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="sunday" enablePopover />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character multi-equal small">
                        <HSRCharacter mode="icon" slug="robin" enablePopover />
                        <HSRCharacter
                          mode="icon"
                          slug="tingyun"
                          enablePopover
                        />
                        <HSRCharacter
                          mode="icon"
                          slug="ruan-mei"
                          enablePopover
                        />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="huohuo" enablePopover />
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Jingliu Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="jingliu"
                          enablePopover
                        />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="sunday" enablePopover />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character multi-equal small">
                        <HSRCharacter mode="icon" slug="robin" enablePopover />
                        <HSRCharacter
                          mode="icon"
                          slug="tingyun"
                          enablePopover
                        />
                        <HSRCharacter
                          mode="icon"
                          slug="ruan-mei"
                          enablePopover
                        />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="huohuo" enablePopover />
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-8">
              <SectionHeader title="What's Next?" />
              <p>
                That's the end of the early impressions article for Sunday and
                we hope you did enjoy it! As we mentioned in the introduction
                section,{' '}
                <strong>
                  full Sunday guide will be released and placed on his profile
                  once he's properly available in the game
                </strong>{' '}
                and then he will be also added to our tier list.
              </p>
              <p>Till then!</p>
              <StaticImage
                src="../../../images/starrail/robin/outro.webp"
                alt="Sunday"
              />
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6',
                'section-7',
                'section-8'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Disclaimer</a>
                </li>
                <li>
                  <a href="#section-1">Introduction</a>
                </li>
                <li>
                  <a href="#section-2">Kit</a>
                </li>
                <li>
                  <a href="#section-3">Eidolons</a>
                </li>
                <li>
                  <a href="#section-4">Relics & Light Cones</a>
                </li>
                <li>
                  <a href="#section-5">Sunday vs Bronya vs Sparkle</a>
                </li>
                <li>
                  <a href="#section-6">Synergy</a>
                </li>
                <li>
                  <a href="#section-7">Teams</a>
                </li>
                <li>
                  <a href="#section-8">What's Next?</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SRGuidesRappa;

export const Head: React.FC = () => (
  <Seo
    title="Sunday - First Impressions | Honkai: Star Rail | Prydwen Institute"
    description="Check the early access first impressions of Sunday - a new character coming soon to Honkai: Star Rail!"
  />
);
